:root {
  --color-blue-dark: #101928;
  --color-blue-darker: #0F1B6F;
  --color-blue-darkest: #111E7A;
  --color-white: #FFFFFF;
  --color-gray: #475367;
  --color-black: #000000;
  --color-blue-bright: #0D5EBA;
  --color-green: #099137;
  --color-blue-deep: #0C1557;
  --background-gradient: linear-gradient(90deg, #21367F -1.06%, #194E91 26.81%, #068BC0 86.55%, #0298CA 98.5%);
  --secondbackground-gradient:  linear-gradient(90deg, #21367F -1.06%, #194E91 26.81%, #068BC0 86.55%, #0298CA 98.5%);
    --ai-gradient:  linear-gradient(90.84deg, #9747FF 40.64%, #1671D9 87.5%);
   --color-blue-about: #012657;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #111E7A;
}

::-webkit-scrollbar-thumb {
  background: #0D5EBA;
}

@font-face {
  font-family: "Inter Regular";
  src: url(./assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter Medium";
  src: url(./assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter Bold";
  src: url(./assets/fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: "Inter SemiBold";
  src: url(./assets/fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: "Inter Light";
  src: url(./assets/fonts/Inter-Light.ttf);
}

@font-face {
  font-family: "Inter ExtraBold";
  src: url(./assets/fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: "Inter Black";
  src: url(./assets/fonts/Inter-Black.ttf);
}

@font-face {
  font-family: "Inter Thin";
  src: url(./assets/fonts/Inter-Thin.ttf);
}

@font-face {
  font-family: "Inter ExtraLight";
  src: url(./assets/fonts/Inter-ExtraLight.ttf);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Inter Bold";
}
h6 {
  font-family: "Inter SemiBold";
}
p {
  font-family: "Inter Regular";
}
body {
  font-family: "Inter Regular";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}