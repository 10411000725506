.customWrapper {
  .customcol1 {
    height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 0 5vw;
       gap: 15px;
      svg {
        max-width: 100%;
        height: auto;
      }

      h2 {
        font-size: 54px;
        margin-bottom: 20px;
        line-height: 60px;

        span {
          background: var(--ai-gradient);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }

      p {
        font-size: 14px;
        color: var(--color-gray);
        margin-bottom: 20px;
        line-height: 28px;
      }

      button {
        background-color: var(--color-blue-darkest);
        color: var(--color-white);
        padding: 10px 20px;
        border-radius: 12px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          background-color: var(--color-blue-darkest);
        }
      }
    }

 

  .customcol2 {
    background: var(--color-blue-bright);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 70px;
    padding: 100px;

    .customcol2text {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: start;
      text-align: start;
      justify-content: start;

      svg {
        max-width: 100%;
        height: auto;
      }

      h2 {
        font-size: 44px;
        margin-bottom: 20px;
        line-height: 60px;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 28px;
      }

      button {
        background-color: var(--color-blue-darkest);
        color: var(--color-white);
        padding: 10px 20px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          background-color: var(--color-blue-darkest);
        }
      }
    }

    .image-cover {
      padding-top: 15vh;
      flex: 1;
      width: 50%;
      height: 50%;
      object-fit: cover;
    }
  }

  .customcol3 {
    text-align: center;
   background-color: white;
 padding-top: 5vh;
   
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 34px;
      margin: 25px 0;
    }

    p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .customcol3spandiv {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Three columns */
      grid-template-rows: auto auto; /* Two rows */
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;
      width: 100%;
      max-width: 1200px;

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
   /* Center the second row items between the first row items */
      span:nth-child(4) {
        grid-column: 1 / span 2;
        justify-self: center;
      }

      // span:nth-child(5) {
      //   grid-column: 0/ span 3;
      //   justify-self: center;
      // }

      h6 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: var(--color-gray);
        line-height: 28px;
      }
    }
  }

  .customcol4 {
    text-align: center;
    margin-bottom: 20px;
  padding-top: 5vh;

    h2 {
      font-size: 34px;
      margin: 25px 0;
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
    }

    button {
      background-color: var(--color-blue-darkest);
      color: var(--color-white);
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-blue-darkest);
      }
    }

    svg {
      max-width: 100%;
    }
  }
}

// Media queries for mobile view
@media (max-width: 768px) {
  .customWrapper {
    .customcol1 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: auto;
      

      .customcol1Text {
        padding-top: 5vh;;
        padding-left: 0;
        align-items: center;

        h2 {
          font-size: 20px;
          line-height: 28px;
        }

        p {
          font-size: 14px;
          text-align: center;
        }

        svg {
          max-width: 100%;
          height: auto;
        }
      }

      .image-cover {
        padding-top: 0;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .customcol2 {
      flex-direction: column;
      align-items: center;
      padding: 20px;
      text-align: center;

      .customcol2text {
        align-items: center;

        h2 {
          font-size: 20px;
        }

        p {
          font-size: 14px;
          text-align: center;
        }

        button {
          font-size: 14px;
        }

        svg {
          max-width: 100%;
          height: auto;
        }
      }

      .image-cover {
        padding-top: 0;
        width: 100%;
      }
    }

    .customcol3 {
      padding: 20px 0;
      text-align: center;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      .customcol3spandiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;

        span {
          text-align: center;
          margin-bottom: 20px;
        }

        svg {
          max-height: 200px;
        }
      }
    }

    .customcol4 {
     
      text-align: center;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      button {
        font-size: 14px;
      }

 

        span {
          display: block;
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          align-items: center;
             svg {
              width: 100%;
          max-height: 300px;
        }
        }

     
      }
    
  }
}
