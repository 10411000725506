.aboutWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .aboutcol1, .aboutcol2, .aboutcol3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15vh;

   

    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .aboutcol1 {
    text-align: center;
    flex-direction: column;
    gap: 20px;
        background-color: var(--color-blue-about);
         color: white;
  }

  .aboutcol2, .aboutcol3 {
    flex-direction: row;

    div:first-child {
      flex: 1;
      text-align: left;
    }

    div:last-child {
      flex: 1;
      text-align: right;
    }
  }

  @media (max-width: 768px) {
    .aboutcol1, .aboutcol2, .aboutcol3 {
      flex-direction: column;
      text-align: center;
      padding: 20px;

      div {
        flex: 1;
        text-align: center;
      }
    }

    .aboutcol2 div:first-child, .aboutcol3 div:last-child {
      order: 2;
    }

    .aboutcol2 div:last-child, .aboutcol3 div:first-child {
      order: 1;
    }
  }
}
