.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  position: sticky;
  z-index: 2000;

  .logo {
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;

    svg {
      // Add any styles for svg if needed
    }
  }

  .links {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
      gap: 20px;

      li {
        list-style: none;
        position: relative;
        cursor: pointer;
        color: #101928;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        .dropdown {
          cursor: pointer;

          .arrow {
            margin-left: 5px;
            cursor: pointer;
          }
        }

        a {
          text-decoration: none;
          color: #101928;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;

          &:hover {
            color: var(--color-blue-dark);
          }
        }

        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          background: #fff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          padding: 10px;
          display: flex;
          width: 200px;
          flex-direction: column;
          gap: 10px;

          a {
            color: #101928;
            font-size: 12px;

            &:hover {
              color: var(--color-blue-dark);
            }
          }
        }
      }
    }

    &.visible {
      display: block;
      position: absolute;
     top: 50px;
      left: 0;
      width: 100%;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 20px 0;

      ul {
        flex-direction: column;
        align-items: center;
        gap: 20px;

        li {
          a {
            font-size: 16px;
          }

          .dropdown-menu {
            position: static;
            width: 100%;
            box-shadow: none;

            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    padding-right: 20px;
    gap: 20px;

    .login {
      background-color: var(--color-white);
      color: var(--color-blue-darkest);
      padding: 10px 20px;
      border-radius: 8px;
      border: 1px solid var(--color-blue-darkest);
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-white);
      }
    }
    .get-started {
      background-color: var(--color-blue-darkest);
      color: var(--color-white);
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-blue-darkest);
      }
    }
  }

  .hamburger-menu {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .logo {
      padding-left: 0;
    }

    .links {
      display: none;

      &.visible {
        display: block;
        position: absolute;
       top: 50px;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px 0;

        ul {
          flex-direction: column;
          align-items: center;
          gap: 20px;

          li {
            a {
              font-size: 16px;
            }

            .dropdown-menu {
              position: static;
              width: 100%;
              box-shadow: none;

              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .buttons {
      display: none;
    }

    .hamburger-menu {
      display: block;
    }
  }
}
