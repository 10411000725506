.fraudWrapper {
  .fraudcol1 {
    height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 0 5vw;
    gap: 15px;
      svg {
        max-width: 100%;
        height: auto;
      }

      h2 {
        font-size: 54px;
        margin-bottom: 20px;
        line-height: 60px;
      }

      p {
        font-size: 14px;
        color: var(--color-gray);
        margin-bottom: 20px;
        line-height: 28px;
      }

      button {
        background-color: var(--color-blue-darkest);
        color: var(--color-white);
        padding: 10px 20px;
        border-radius: 12px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          background-color: var(--color-blue-darkest);
        }
      }

  }

  .fraudcol2 {
    background: var(--secondbackground-gradient);
    color: white;
    text-align: center;

    padding: 20px 0;

    h2 {
      font-size: 34px;
      margin: 25px 0;
    }

    p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .fraudcol2spandiv {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Three columns */
      text-align: start;
      margin-bottom: 20px;
      gap: 50px;
      padding: 20px 90px;

      span {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 10px;
        text-wrap: wrap;
      }
 
      h6 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: var(--color-white);
        line-height: 28px;
      }
    }
  }

  .fraudcol3 {
    text-align: center;
    background-color: white;
  padding-top: 5vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 34px;
      margin: 25px 0;
    }

    p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .fraudcol3spandiv {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Three columns */
      grid-template-rows: auto auto; /* Two rows */
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;
      width: 100%;
      max-width: 1200px;

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
      /* Center the second row items between the first row items */
      span:nth-child(4) {
        grid-column: 1 / span 2;
        justify-self: center;
      }

      h6 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: var(--color-gray);
        line-height: 28px;
      }
    }
  }

  .fraudcol4 {
    text-align: center;
  padding-top: 5vh;
    background-color: white;

    h2 {
      font-size: 34px;
      margin: 25px 0;
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
    }

    button {
      background-color: var(--color-blue-darkest);
      color: var(--color-white);
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-blue-darkest);
      }
    }

    svg {
      max-width: 100%;
    }

    .features {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-top: 20px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: var(--color-gray);
      }
    }
  }
}

// Media queries for mobile view
@media (max-width: 768px) {
  .fraudWrapper {
    .fraudcol1 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: auto;

   
      h2 {
          font-size: 20px;
          line-height: 28px;
        }

        p {
          font-size: 14px;
          text-align: center;
        }

        svg {
          max-width: 100%;
          height: auto;
        }
      
    }

    .fraudcol2 {
      padding: 20px 0;
      text-align: center;
      

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      .fraudcol2spandiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;

        span {
          text-align: center;
          margin-bottom: 20px;
          align-items: center;

        }

        svg {
          max-height: 200px;
        }
      }
    }

    .fraudcol3 {
   
      text-align: center;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      .fraudcol3spandiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;

        span {
          text-align: center;
          margin-bottom: 20px;
        }

        svg {
          max-height: 200px;
        }
      }
    }

    .fraudcol4 {
      text-align: center;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

        span {
          display: block;
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          
        svg {
          max-height: 300px;
          width: 100%;
        }
        }

      
    }
  }
}
