.home {
  .intro {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 54px;
      margin: 40px 0;
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
    }

    .get-started {
      background-color: var(--color-blue-darkest);
      color: var(--color-white);
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      animation: fadeIn 2s ease-in-out; /* Add this line */

      &:hover {
        background-color: var(--color-blue-darkest);
      }
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  .dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  .secondhero {
    text-align: center;
    margin-bottom: 60px;
    background: var(--background-gradient);
    color: white;
    padding: 40px 5px;

    h2 {
      font-size: 34px;
      margin: 25px 0;
    }

    p {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .secondherocards {
      display: flex;
      gap: 30px;
      justify-content: center;
      align-items: center;
      padding: 20px 0;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .thirdhero {
    text-align: center;
    margin-bottom: 60px;

    h2 {
      font-size: 24px;
      margin: 20px 0;
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
    }

    .thirdherosvg {
      svg {
        max-width: 100%;
        height: auto;
      }
    }

    .thirdHerobuttomcards {
      display: flex;
      gap: 30px;
      justify-content: center;
      align-items: center;
      padding: 20px 0;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .fourthero {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      margin: 20px 0;
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
    }

    .try-for-free {
      font-size: 14px;
      color: var(--color-white);
      background: var(--color-blue-darkest);
      padding: 10px 20px;
      border-radius: 12px;
      outline: none;
      border: none;
      cursor: pointer;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .features {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 70px;

      span {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: var(--color-gray);
      }
    }
  }
}

// Define the keyframe animation
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Media queries for iPad view
@media (max-width: 1024px) {
  .home {
    .intro,
    .secondhero,
    .thirdhero,
    .fourthero {
      h2 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }
    }

    .dashboard,
    .secondherocards,
    .thirdHerobuttomcards,
    .features {
      svg {
        max-width: 100%;
      }
    }
  }
}

// Media queries for mobile view
@media (max-width: 768px) {
  .home {
    .intro {
      margin-bottom: 10px;
      margin-top: 10px;

      svg {
        max-width: 70%;
      }

      h2 {
        font-size: 20px;
        margin: 20px 0;
      }

      p {
        font-size: 14px;
      }

      .get-started {
        font-size: 14px;
      }
    }

    .dashboard {
      flex-direction: column;
      margin-bottom: 0px;

      span {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
      }

      svg {
        max-width: 100%;
      }
    }

    .secondhero {
      margin-bottom: 0px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      .secondherocards {
        flex-direction: column;
        gap: 10px;

        span {
          display: block;
          width: 100%;
          text-align: center;
        }

        svg {
          max-width: 100%;
          height: auto;
          max-height: 200px;
        }
      }
    }

    .thirdhero {
      margin-bottom: 0px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      .thirdherosvg {
        svg {
          max-width: 100%;
          height: auto;
          max-height: 200px;
        }
      }

      .thirdHerobuttomcards {
        flex-direction: column;
        gap: 10px;

        span {
          display: block;
          width: 100%;
          text-align: center;
        }

        svg {
          max-width: 100%;
        }
      }
    }

    .fourthero {
      margin-bottom: 0px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      .try-for-free {
        font-size: 14px;
      }

      span {
        display: block;
        width: 100%;
        text-align: center;

        svg {
          max-width: 100%;
          height: auto;
          max-height: 250px;
        }
      }

      .features {
        flex-direction: column;
        gap: 10px;
        padding: 0px 20px;

        span {
          display: block;
          width: 100%;
          text-align: center;

          svg {
            max-width: 100%;
          }
        }
      }
    }
  }
}
