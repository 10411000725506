.Aiwrapper {
  .aicol1 {
    height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 0 5vw;
    gap: 10px;

    svg {
      max-width: 100%;
      height: auto;
    }

    h2 {
      font-size: 54px;
      margin-bottom: 20px;
      line-height: 60px;

      span {
        background: var(--ai-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
      line-height: 28px;
    }

    button {
      background-color: var(--color-blue-darkest);
      color: var(--color-white);
      padding: 10px 20px;
      border-radius: 12px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-blue-darkest);
      }
    }
  }

  .aicol2 {
    padding: 20px;
    align-items: center;
    text-align: center;

    h5 {
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 60px;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      span {
        flex: 1;

        h6 {
          font-size: 18px;
          color: var(--color-gray);
          margin-bottom: 20px;
          line-height: 28px;
        }

        p {
          font-size: 14px;
          color: var(--color-gray);
          margin-bottom: 20px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .aicol1 {
      h2 {
        font-size: 36px;
        line-height: 44px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }

      button {
        font-size: 12px;
        padding: 8px 16px;
      }
    }

    .aicol2 {
      h5 {
        font-size: 20px;
        line-height: 28px;
      }

      div {
        flex-direction: column;
        gap: 20px;

        span {
          h6 {
            font-size: 16px;
            line-height: 24px;
          }

          p {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .aicol1 {
      h2 {
        font-size: 28px;
        line-height: 36px;
      }

      p {
        font-size: 10px;
        line-height: 18px;
      }

      button {
        font-size: 10px;
        padding: 6px 12px;
      }
    }

    .aicol2 {
      h5 {
        font-size: 18px;
        line-height: 24px;
      }

      div {
        flex-direction: column;
        gap: 10px;

        span {
          h6 {
            font-size: 14px;
            line-height: 20px;
          }

          p {
            font-size: 10px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
