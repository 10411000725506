.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #333;
  color: #fff !important;
  
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.dots {
  display: flex;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.red {
  background-color: #ff5f56 !important;
}

.yellow {
  background-color: #ffbd2e !important;
}

.green {
  background-color: #27c93f !important;
}

.copy-icon {
  cursor: pointer;
  color: #fff !important;
}

.card-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px;
}

.curl-command {
  background: #444;
  padding: 8px;
  border-radius: 4px;
  padding: 10px;
  position: relative !important;
  word-wrap: break-word !important;  /* Ensures long lines break */
  overflow-wrap: break-word !important; /* Ensures long lines break */
}

.curl-command pre {
  margin: 0;
  color: #fff !important;
  text-align: start !important;
  padding: 10px;
  line-height: 1.5 !important;
  white-space: pre-wrap !important; /* Ensures the text wraps inside the pre element */
}
