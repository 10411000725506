.loanWrapper {
  .loancol1 {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 0 5vw;
    gap: 10px;



      svg {
        max-width: 100%;
        height: auto;
      }

      h2 {
        font-size: 54px;
        margin-bottom: 20px;
        line-height: 60px;
      }

      p {
        font-size: 14px;
        color: var(--color-gray);
        margin-bottom: 20px;
        line-height: 28px;
      }

      button {
        background-color: var(--color-blue-darkest);
        color: var(--color-white);
        padding: 10px 20px;
        border-radius: 12px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          background-color: var(--color-blue-darkest);
        }
      }
    

  }

  .loancol2, .loancol3 {
    background-color: white;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 7vh;

    h2 {
      font-size: 24px;
      line-height: 60px;

      span {
        background: var(--ai-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
      line-height: 28px;
    }

    .loancol2child, .loancol3child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;

      .loancol2text, .loancol3text {
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: start;
        justify-content: start;

        p {
          display: flex;
          padding-top: 4vh;
          align-items: center;
          gap: 30px;
          font-size: 15px;
          line-height: 28px;
          color: black;
        }
      }

      .loancol2image, .loancol3image {
        display: flex;
        align-items: center;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .loancol1, .loancol2, .loancol3 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;

      .loancol1Text, .loancol2text, .loancol3text {
        align-items: center;
        text-align: center;
        padding: 0;
      }

      .image-cover, .loancol2image, .loancol3image {
        width: 100%;
        padding-top: 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .loancol2child, .loancol3child {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
