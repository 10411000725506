.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;

  .footerCol1 {
    padding-top: 7vh;
    padding-bottom: 4vh;

    h2 {
      margin-bottom: 10px;
      text-align: center;
    }

    .faq-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .faq-item {
        width: 100%;
        display: flex;
        max-width: 600px;
        flex-direction: column;
        align-items: flex-start;

        h6 {
          margin: 5px 0;
          cursor: pointer;
          background-color: white;
          color: black;
          padding: 20px;
          margin-right: 200px;
          font-size: 20px;
          width: 100%;
          box-sizing: border-box;
          position: relative;

          &::after {
            content: '▼';
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 0.3s ease;
          }

          &.open::after {
            transform: rotate(180deg);
          }
        }

        p {
          margin: 0;
          padding: 20px;
          display: none;
          background-color: #f9f9f9;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
          text-wrap: wrap;
          box-sizing: border-box;
        }

        .open {
          display: block;
        }
      }
    }
  }

  .footerCol2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #0c1557;
    color: white;
    padding: 50px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;

    .newsletter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h2,
      p {
        flex: 1;
      }

      form {
        display: flex;
        gap: 10px;
        flex: 1;

        input {
          padding: 10px;
          border: none;
          border-radius: 5px;
          flex: 1;
        }

        button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          background-color: #007bff;
          color: white;
          cursor: pointer;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }

  .footerCol3 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    .product,
    .social,
    .contact {
      flex: 1;
      margin: 0 10px;

      h3 {
        margin-bottom: 10px;
      }

      a {
        display: block;
        color: white;
        font-size: 1rem;
        margin-bottom: 5px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        margin-bottom: 5px;
      }
    }
  }

  .footerCol4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;

    .logo img {
      height: 50px;
    }

    .copyright {
      color: whitesmoke;
    }
  }
}

@media (max-width: 768px) {
  .footerWrapper {
    

    .footerCol1 {
      padding: 0;
      width: 100%;

      .faq-list {
        .faq-item {
          h6 {
            margin-right: 0;
          }
        }
      }
    }

    .footerCol2 {
      width: 100%;
      padding: 20px;

      .newsletter {
        flex-direction: column;
        align-items: stretch;

        h2 {
          text-align: center;
          margin-bottom: 20px;
        }

        form {
          flex-direction: column;
          gap: 10px;
          
          input {
            width: 100%;
          }

          button {
            width: 100%;
          }
        }
      }
    }

    .footerCol3 {
      width: 100%;
      margin-top: 0;
      flex-direction: column;

      .product,
      .social,
      .contact {
        margin: 10px 0;
      }
    }

    .footerCol4 {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 0;

      .logo img {
        margin-bottom: 10px;
      }
    }
  }
}
