.devWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;

  .devcol1 {
    height: 76vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 0 5vw;
    gap: 10px;

    svg {
      max-width: 100%;
      height: auto;
    }

    h2 {
      font-size: 54px;
      margin-bottom: 20px;
      line-height: 60px;

      span {
        background: var(--ai-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
      line-height: 28px;
    }

    button {
      background-color: var(--color-blue-darkest);
      color: var(--color-white);
      padding: 10px 20px;
      border-radius: 12px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-blue-darkest);
      }
    }
  }

  .devcol2 {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    text-align: center;
    color: #1d2739;

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 32px;
    }

    p {
      font-size: 14px;
      color: var(--color-gray);
      margin-bottom: 20px;
      line-height: 24px;
    }

    .spandiv {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;

      .spangroup {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        span {
          width: calc(50% - 20px); // Adjust width as needed based on gap size
          background:  #333;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

          div {
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            color: white;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .devcol1 {
      h2 {
        font-size: 36px;
        line-height: 44px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }

      button {
        font-size: 12px;
        padding: 8px 16px;
      }
    }

    .devcol2 {
      h2 {
        font-size: 20px;
        line-height: 28px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }

      .spandiv {
        gap: 10px;
      }
    }
  }

  @media (max-width: 480px) {
    .devcol1 {
      h2 {
        font-size: 28px;
        line-height: 36px;
      }

      p {
        font-size: 10px;
        line-height: 18px;
      }

      button {
        font-size: 10px;
        padding: 6px 12px;
      }
    }

    .devcol2 {
      h2 {
        font-size: 18px;
        line-height: 24px;
      }

      p {
        font-size: 10px;
        line-height: 18px;
      }

      .spandiv {
        gap: 5px;
      }
    }
  }
}
