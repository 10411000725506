
/* ContactUs Component */
#contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 100vh;
  background-color: #f9f9f9;

  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: var(--color-blue-dark);
    text-align: center;
  }

  p {
    text-align: center;
    max-width: 600px;
    margin-bottom: 1.5rem;
    color: var(--color-blue-dark);
  }
}

/* FormSparkForm Component */
#form-container {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 2rem;
      justify-content: center;
    align-items: center;
    text-align: center;

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
   div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   h6{
    font-size: 1.5rem;
    color: var(--color-blue-dark);
 
   }
   p{
    text-align: center;
    max-width: 600px;
    margin-bottom: 1.5rem;
    color: var(--color-blue-dark);
   }
   }

  }

  input,
  textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: var(--color-blue-darkest);
      outline: none;
    }
  }

  input[type="text"],
  input[type="email"] {
    height: 40px;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  button {
    background-color: var(--color-blue-darkest);
    color: #fff;
    border: none;
    padding: 0.75rem;
    width: 100%;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
      background-color: #cccccc;
    }

    &:hover:not(:disabled) {
      background-color: var(--color-blue-dark);
    }
  }

  .success-message {
    color: green;
    font-weight: bold;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  #form-container {
    padding: 1.5rem;
  }
}
